import Swiper from 'swiper';

export default () => {
  const coverflows = document.querySelectorAll('.js-coverflow');

  [ ...coverflows ].forEach(coverflow => {
    const slides = coverflow.querySelectorAll('.js-coverflow__element');
    const inner = coverflow.querySelectorAll('.js-coverflow__inner')[0];
    const next = coverflow.querySelectorAll('.js-coverflow__next')[0];
    const prev = coverflow.querySelectorAll('.js-coverflow__prev')[0];
    const middleSlide = Math.floor(slides.length / 2);
    const swiper = new Swiper(inner, {
      a11y: true,
      effect: 'coverflow',
      centeredSlides: true,
      slidesPerView: 3,
      keyboardControl: true,
      mousewheelControl: false,
      lazyLoading: true,
      preventClicks: false,
      preventClicksPropagation: false,
      lazyLoadingInPrevNext: true,
      coverflow: {
        rotate: 30,
        stretch: 0,
        depth: 250,
        modifier: 1,
        slideShadows: true
      },
      breakpoints: {
        640: {
          slidesPerView: 1
        },
        920: {
          slidesPerView: 2
        }
      }
    });

    // on load, slide to the middle slide, looks better
    swiper.slideTo(middleSlide);

    // find the next/prev buttons outside of swiper
    next.addEventListener('click', event => {
      event.preventDefault();
      swiper.slideNext();
    });

    // find the next/prev buttons outside of swiper
    prev.addEventListener('click', event => {
      event.preventDefault();
      swiper.slidePrev();
    });
  });
};
