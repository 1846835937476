import $ from 'jquery';

export default () => {
  const $nav = $('#nav');
  const $toggle = $('.js-nav-toggle');
  const $body = $('body');
  const $header = $('.header');
  const $linkWithChildren = $nav.find('.menu-item-has-children > a');

  $toggle.on('click', () => {
    $header.toggleClass('header--nav-active');
  });

  $linkWithChildren.on('click', event => {
    const $currentNav = $(event.currentTarget).parent();
    event.preventDefault();

    $nav.on('click.nav', e => {
      e.stopPropagation();
    });

    $body.on('click', () => {
      $nav.find('.menu-item-has-children').removeClass('menu-item--collapsed');
      $body.off('click.nav');
    });

    if ($currentNav.hasClass('menu-item--collapsed')) {
      $nav.find('.menu-item-has-children').removeClass('menu-item--collapsed');
    } else {
      $nav.find('.menu-item-has-children').removeClass('menu-item--collapsed');
      $currentNav.toggleClass('menu-item--collapsed');
    }
  });
};
