export default () => {
  const toggles = document.querySelectorAll('.js-toggle');

  [].forEach.call(toggles, toggle => {
    const target = document.getElementById(toggle.getAttribute('data-toggle-target'));
    const cssClass = toggle.getAttribute('data-toggle-class');
    const cssClassSelf = toggle.getAttribute('data-toggle-self');

    toggle.addEventListener('click', event => {
      event.preventDefault();
      target.classList.toggle(cssClass);
      toggle.classList.toggle(cssClassSelf);
    });
  });
};
