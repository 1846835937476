import $ from 'jquery';
import _throttle from 'lodash/throttle';
import mediaquery from '@helpers/mediaquery';

export default () => {
  const $win = $(window);
  const $parallaxes = $('.js-parallax');

  $win.on('scroll', _throttle(() => {
    if (mediaquery('l')) {
      const scrolled = parseInt($win.scrollTop(), 10);

      $parallaxes.each((i, p) => {
        const backgroundOffset = (scrolled / -4) + ((i - 1) * 100);

        if (!$(p).hasClass('coverimage--first')) {
          $(p).css({
            backgroundPosition: `50% ${backgroundOffset}px`,
            marginTop: `${scrolled / -8}px`
          });
        }
      });
    }
  }, 40));

  $win.scrollTop(1).scrollTop(0);
};
