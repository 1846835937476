import $ from 'jquery';
import _throttle from 'lodash/throttle';

export default () => {
  const $win = $(window);
  const $top = $('.js-footer__top');

  $win.on('scroll', _throttle(() => {
    const scrolled = $win.scrollTop();
    const $header = $('.header');
    const $footer = $('.footer');

    if (scrolled > $header.outerHeight()) {
      $footer.addClass('footer--scrolled');
    } else {
      $footer.removeClass('footer--scrolled');
    }
  }, 40));

  $top.on('click', event => {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, 350);
  });
};
