import PhotoSwipe from 'photoswipe';
import PhotoSwipeUi from '@nodemodules/photoswipe/dist/photoswipe-ui-default';

export default () => {
  const pswp = document.querySelectorAll('.js-photoswipe')[0];
  const lightboxes = document.querySelectorAll('.js-lightbox');
  const buttons = document.querySelectorAll('.pswp__button');
  const options = { // photoswipe options, override
    index: 0
  };

  [ ...lightboxes ].forEach(lightbox => {
    const thumbLinks = lightbox.querySelectorAll('.js-lightbox__thumb');
    const init = lightbox.querySelectorAll('.js-lightbox-init');
    const items = [];
    let gallery = false;

    [ ...thumbLinks ].forEach(link => {
      items.push({
        src: link.getAttribute('href'),
        title: link.getAttribute('data-image-title'),
        w: parseInt(link.getAttribute('data-image-width'), 10),
        h: parseInt(link.getAttribute('data-image-height'), 10)
      });
    });

    init[0].addEventListener('click', event => {
      event.preventDefault();
      gallery = new PhotoSwipe(pswp, PhotoSwipeUi, items, options);
      gallery.init();
    });
  });

  // fix clicking on svg inside of button
  [ ...buttons ].forEach(button => {
    const svgs = button.querySelectorAll('svg');
    [ ...svgs ].forEach(svg => {
      svg.addEventListener('click', event => {
        const newEvent = document.createEvent('HTMLEvents');
        newEvent.initEvent('click', true, false);
        event.preventDefault();
        button.dispatchEvent(newEvent);
      });
    });
  });
};
