import $ from 'jquery';
import _throttle from 'lodash/throttle';

export default () => {
  const $win = $(window);

  $win.on('scroll', _throttle(() => {
    const scrolled = $win.scrollTop();
    const $header = $('.header');
    const $logo = $('.logo');

    if (scrolled > $header.outerHeight()) {
      $logo.addClass('logo--scrolled');
    } else {
      $logo.removeClass('logo--scrolled');
    }
  }, 40));
};
