import $ from 'jquery';

const scrollTo = target => {
  const $header = $('.header');
  const $target = $(target);
  const offset = $target.offset().top;

  $('html, body').animate({
    scrollTop: offset - (offset / 8) - ($header.outerHeight() * 3)
  }, 350);
};

export default () => {
  const $scroll = $('.js-scroll');

  $scroll.on('click', event => {
    event.preventDefault();
    scrollTo($(event.currentTarget).attr('href'));
  });

  // if the page has a has, use this as scroll target smoothly
  if (document.location.hash !== '') {
    scrollTo(document.location.hash);
  }
};
