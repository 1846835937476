import $ from 'jquery';
import mediaquery from '@helpers/mediaquery';

export default () => {
  const $header = $('.header');
  const $form = $header.find('.js-searchform');
  const $submit = $form.find('.js-searchform__submit');
  const $input = $form.find('.js-searchform__input');
  const visibleClass = 'searchform--visible';

  $submit.on('click', event => {
    if (mediaquery('xxl')) {
      event.preventDefault();

      // on desktop, a click on the loupe will toggle
      // the searchform to be visible

      if ($form.hasClass(visibleClass)) {
        if ($input.val().trim() === '') {
          $form.removeClass(visibleClass);
          $input.blur();
        } else {
          $form.submit();
        }
      } else {
        $form.addClass(visibleClass);
        $input.focus();
      }
    }
  });
};
