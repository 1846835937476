import $ from 'jquery';

export default () => {
  const $links = $('.js-aside__link');
  const $overlays = $('.js-aside__overlay');

  $links.on('click', event => {
    const $link = $(event.currentTarget);
    const target = $link.attr('href').replace('#', '');
    const $overlay = $overlays.filter(`#overlay-${target}`);

    event.preventDefault();

    if ($link.hasClass('aside__link--active')) {
      $links.removeClass('aside__link--active');
      $overlays.removeClass('aside__overlay--visible');
    } else {
      $links.removeClass('aside__link--active');
      $overlays.removeClass('aside__overlay--visible');
      $link.addClass('aside__link--active');
      $overlay.addClass('aside__overlay--visible');
    }
  });
};
