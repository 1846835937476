import $ from 'jquery';

export default () => {
  const $buttons = $('.js-home-more');
  const $overlay = $('#home-overlay');
  const $body = $('body');

  $buttons.on('click', event => {
    const $button = $(event.currentTarget);
    const $more = $button.next('.article--home__moretext');

    event.preventDefault();
    event.stopPropagation();

    if ($overlay.hasClass('article--home__overlay--visible')) {
      $body
        .trigger('click.homeoverlay');
    } else {
      $overlay
        .addClass('article--home__overlay--visible')
        .html($more.html())
        .on('click', e => {
          e.stopPropagation();
        });

      $body.on('click.homeoverlay', () => {
        $overlay
          .removeClass('article--home__overlay--visible')
          .empty();
        $body.off('click.homeoverlay');
      });
    }
  });
};
